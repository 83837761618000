import React from 'react'

import Layout from '../components/layout'

import logoCtr from '../images/logo-ctr.svg'
import gitHubIcon from '../images/github.svg'
import gitHubIconInverse from '../images/github-white.svg'
import gitLabIcon from '../images/gitlab.svg'
import bitBucketIcon from '../images/bitbucket.svg'
import caretDown from '../images/caret-down.svg'
import howDoesItWorkImage from '../images/how-does-it-work.svg'
import beforeAndWithCtrrun from '../images/before-and-with-ctrrun.svg'
import automaticBuildsImage from '../images/automatic-builds.svg'
import parallelBuildStepExecutionImage from '../images/parallel-build-step-execution.svg'
import gitInheritedAuthorizationImage from '../images/git-inherited-authorization.svg'
import onDemandBuildsImage from '../images/on-demand-builds.svg'
import worksWithK8Image from '../images/works-with-k8.svg'
import justInTimeDockerBuildImage from '../images/just-in-time-docker-build.svg'
import poweredByBuildkitImage from '../images/powered-by-buildkit.svg'
import globalCacheImage from '../images/global-cache.svg'
import efficiencyImage from '../images/efficiency-image.svg'
import cloudProvidersLogosImage from '../images/cloud-providers-logos.svg'

const IndexPage = () => {
  return (
    <Layout className="home">
      <header>
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-5 pb-5">
              <div className="mt-5 d-flex flex-row align-items-center">
                <img src={logoCtr} alt="" height="43" className="mr-2" />{' '}
                <span style={{ fontSize: 32, fontWeight: 500 }}>Ctr.run</span>
              </div>
              <h1 className="mt-5 mb-5">
                Modern, fast, efficient &amp; on-demand Docker builds for all
                your application needs
              </h1>

              <div className="login-container">
                <div className="login-dropdown">
                  <div className="login-button">
                    <img src={gitHubIconInverse} alt="" /> Sign up with GitHub
                  </div>
                  <div className="caret">
                    <img src={caretDown} alt="" />
                  </div>
                </div>
              </div>

              <div className="mt-3 text-muted d-flex flex-row align-items-center providers">
                works with <img src={gitHubIcon} alt="GitHub" />{' '}
                <img src={gitLabIcon} alt="GitLab" />{' '}
                <img src={bitBucketIcon} alt="BitBucket" />
              </div>

              <div className="mt-4 d-inline-block">
                Shut down as of August 20th
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="inverse pb-5 pt-5">
        <div className="container">
          <div className="row text-center">
            <div className="col">
              <h2>How does it work?</h2>
              <div className="mt-4 mb-5">
                <img src={howDoesItWorkImage} className="img-fluid" alt="" />
              </div>
              <p className="mb-4">Try it with any public git repo!</p>
              <div className="mb-3">
                <code>
                  <span style={{ userSelect: 'none' }}>$ </span>docker run
                  ctr.run/github.com/undefinedlabs/hello-world:master
                </code>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="dark">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <h2 className="mb-3">Why Ctr.run?</h2>
              <p className="lead">
                We built Ctr.run to stop wasting time and money building,
                pushing, and storing container images that never get used. To
                speed up all container builds with automatic caching and
                parallelism that works everywhere: locally, across CI providers,
                and container runtimes.
              </p>
              <p className="lead">
                And to eliminate the guesswork of what :tag to pull to see the
                latest changes in master or a feature-branch.
              </p>
            </div>
            <div className="col-sm-6">
              <img src={beforeAndWithCtrrun} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container text-center">
          <div className="row mb-3">
            <div className="col">
              <p>
                Using ctr.run is easy, simply <code>docker run</code> the git
                commit, branch or tag you need, when you need it:
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <img src={automaticBuildsImage} alt="" />
              <p>Automatic build caching</p>
            </div>
            <div className="col">
              <img src={parallelBuildStepExecutionImage} alt="" />
              <p>Git-inherited authorization</p>
            </div>
            <div className="col">
              <img src={gitInheritedAuthorizationImage} alt="" />
              <p>Parallel build step execution</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img src={onDemandBuildsImage} alt="" />
              <p>On-demand builds</p>
            </div>
            <div className="col">
              <img src={worksWithK8Image} alt="" />
              <p>Works with k8s and other container runtimes</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row subsection">
            <div className="offset-sm-1 col-sm-4 order-sm-last">
              <img
                className="img-fluid mb-4 mb-sm-0"
                src={justInTimeDockerBuildImage}
                alt=""
              />
            </div>
            <div className="offset-sm-1 col-sm-5">
              <h4>JIT (just-in-time) Docker Build</h4>
              <p>
                Execute <code>docker run ctr.run/github.com/...</code> and
                ctr.run will automatically build your container image – faster
                than you ever imagined possible.
              </p>
            </div>
          </div>

          <div className="row subsection">
            <div className="offset-2 col-sm-4">
              <img
                className="img-fluid mb-4 mb-sm-0"
                src={poweredByBuildkitImage}
                alt=""
              />
            </div>
            <div className="col-sm-5">
              <h4>The fastest builder West of the Mississippi</h4>
              <p>
                ctr.run is built using the most innovative, efficient and
                fastest container build system in the world: buildkit. Thanks to
                buildkit, build steps are automatically cached and parallelized,
                and all builds are incremental by default.
              </p>
            </div>
          </div>

          <div className="row subsection">
            <div className="offset-sm-1 col-sm-4 order-sm-last">
              <img
                className="img-fluid mb-4 mb-sm-0"
                src={globalCacheImage}
                alt=""
              />
            </div>
            <div className="offset-sm-1 col-sm-5">
              <h4>Internet-scale caching</h4>
              <p>
                ctr.run uses CDN-based edge caching to guarantee availability
                and bring you the fastest pulls anywhere around the globe. Take
                that and rewind it back.
              </p>
            </div>
          </div>

          <div className="row subsection">
            <div className="offset-2 col-sm-4">
              <img
                className="img-fluid mb-4 mb-sm-0"
                src={efficiencyImage}
                alt=""
              />
            </div>
            <div className="col-sm-5">
              <h4>Efficiency FTW</h4>
              <p>
                Stop building and pushing images that you'll never use. It's
                common to build and push to a registry as part of CI, but many
                of these images rarely get used. ctr.run only builds the
                container images you'll actually run.
              </p>
            </div>
          </div>

          <div className="row subsection">
            <div className="offset-sm-1 col-sm-5">
              <h4>Deprecate build & push from your toolchain</h4>
              <p>
                Imagine there's a new commit to try out. It has a Dockerfile,
                and there's an image in DockerHub, but it's from last week's
                release, what do you do?
              </p>
              <p>
                <code>
                  docker run ctr.run/github.com/namespace/repo[:commit-hash,
                  branch-name, tag-name]
                </code>
              </p>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </section>

      <section>
        <div className="container text-center">
          <div className="row">
            <div className="col">
              <h3 className="mb-4">
                Works wherever your container needs take you
              </h3>
              <div className="mb-4">
                <img
                  src={cloudProvidersLogosImage}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offset-sm-3 col-sm-6">
              <p className="lead">
                Kubernetes ◇ AWS ECS ◇ AWS Fargate ◇ AKS ◇ GKS ◇ Github Actions
                ◇ Jenkins ◇ CircleCI ◇ Docker for Mac/Windows ◇ Okteto ◇ k3s ◇
                OpenShift ◇ Pivotal Cloud Foundry ◇{' '}
                <span className="text-muted">and everywhere else!</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="last"></section>
    </Layout>
  )
}

export default IndexPage
